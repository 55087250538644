<template>
  <div class="section">
    <div
      v-if="type === 'standard'"
      class="section__row row g-0"
      style="min-height: 50vh"
    >
      <div
        class="col-lg-6 col-xs-12"
        :class="imgSide === 'right' ? 'order-lg-last' : ''"
      >
        <div
          v-if="imgURL.includes('youtu.be')"
          style="background-color: black; display: flex; align-content:center; height:100%"
        >
          <YoutubeVideo
            :src="imgURL"
            class="ratio ratio-16x9"
            :width="auto"
            :height="null"
            style="width:100%; padding-top: 16.25%;"
          />
        </div>
        <span v-else>
          <figure class="m-0">
            <img
              :src="require(`@/assets/img/${imgURL}`)"
              class="d-lg-none"
              style="width: 100%;"
              loading="lazy"
              alt=""
            />
            <!-- <figcaption
              v-if="caption"
              v-html="caption"
              style="background: rgba(0,0,0,.2); padding: 1rem; text-shadow: 0 1px 1px rgba(0,0,0,.5); font-size: .875rem;"
            /> -->
          </figure>

          <BackgroundImage
            :img="imgURL"
            style="margin-bottom: 0;"
            class="d-none d-lg-block"
          ></BackgroundImage>
        </span>
      </div>
      <div
        class="col-lg-6 p-5 d-flex flex-column justify-content-center align-items-start"
        :class="color"
      >
        <div data-aos="fade">
          <slot></slot>
        </div>
      </div>
    </div>
    <div
      v-if="type === 'inset'"
      class="container-fluid container-lg mx-auto section__row row g-5"
      style="padding: 4rem 0"
    >
      <div class="col-lg-5" :class="imgSide === 'right' ? 'order-lg-last' : ''">
        <div
          v-if="imgURL.includes('youtu.be')"
          style="background-color: black; display: flex; align-content:center;"
        >
          <YoutubeVideo
            :src="imgURL"
            class="ratio ratio-16x9"
            :width="auto"
            :height="null"
          />
        </div>
        <figure class="m-0" v-else>
          <img
            class="p-lg-4 p-2"
            :src="require(`@/assets/img/${imgURL}`)"
            loading="lazy"
          />
        </figure>
      </div>
      <div
        class="col-lg-7 d-flex flex-column align-items-start justify-content-center p-5 p-lg-0 mt-0"
      >
        <slot></slot>
      </div>
    </div>
    <div v-if="type === 'full'" class="section__row row align-items-center g-0">
      <div class="col-lg-4" :class="imgSide === 'right' ? 'order-lg-last' : ''">
        <div
          v-if="imgURL.includes('youtu.be')"
          style="display: flex; align-content:center;"
          class="px-5 text-center"
        >
          <YoutubeVideo
            :src="imgURL"
            class="ratio ratio-16x9"
            :width="auto"
            :height="null"
          />
        </div>
        <figure class="m-0" v-else>
          <img
            :src="require(`@/assets/img/${imgURL}`)"
            style="width:100%;"
            loading="lazy"
          />
        </figure>
      </div>
      <div
        class="col-lg-8  p-5 d-flex flex-column justify-content-center align-items-start"
      >
        <slot></slot>
      </div>
    </div>
    <div v-if="type === 'text'" class="container p-5" style="padding: 5rem 0">
      <slot></slot>
    </div>
    <div v-if="type === 'imgBackground'">
      <div
        class="d-flex flex-column justify-content-center text-white p-lg-5 p-4"
        :class="{
          'align-items-center': textAlign === 'center',
          'align-items-start': textAlign === 'left',
          'align-items-end': textAlign === 'right'
        }"
        style="background-size: cover; background-position: center; min-height:500px;"
        :style="{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)), url(${require('@/assets/img/' +
            imgURL)})`,
          width: '100%',
        }"
      >
        <div
          class="container d-flex flex-column"
            :class="{
              'align-items-center': textAlign === 'center',
              'align-items-start pe-md-20p': textAlign === 'left',
              'align-items-end ps-md-20p': textAlign === 'right'
            }"
        >
          <slot></slot>
        </div>
      </div>
      <!-- <div class="d-block d-lg-none container">
        <img :src="require(`@/assets/img/${imgURL}`)" loading="lazy" />
        <div class="pt-5">
          <slot></slot>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import BackgroundImage from "@/components/BackgroundImage.vue";
import YoutubeVideo from "vue3-youtube";
export default {
  name: "Section",
  /**
   * To use "standard" section with a YT video, pass the video URL to the component as imgURL.
   * If the url includes 'youtu.be' it will render a YoutubeVideo component instead of an image.
   */
  props: {
    type: {
      type: String,
      default: "standard",
      validator: (propValue) => {
        return ["standard", "text", "inset", "full", "imgBackground"].includes(
          propValue
        );
      },
    },
    /**
     * Determines if section has background image with text overlay
     */
    imgBackground: { type: Boolean, default: false },
    imgSide: { type: String, default: "left" },
    imgURL: { type: String, required: true },
    color: { type: String, default: "grey" },
    textAlign: { type: String, default: "left" },
    caption: { type: String, default: null },
  },
  components: {
    // Btn,
    BackgroundImage,
    YoutubeVideo,
  },
};
</script>
<style scoped lang="scss">
.section {
  &__heading,
  :slotted(h3) {
    @include heading-base;
    font-size: $header-sm;
    margin-bottom: 1rem;
  }
  &__text,
  :slotted(p),
  :slotted(li) {
    font-family: $font-family-serif;
  }
  &__text,
  :slotted(p) {
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-up(lg) {
  .section {
    &__heading,
    :slotted(h3) {
      font-size: $header-xl;
    }
    &__text,
    :slotted(p),
    :slotted(li) {
    }
  }
}
</style>
