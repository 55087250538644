<template>
  <div class="bg-image" :style="computedStyle"></div>
</template>

<script>
export default {
  name: 'BackgroundImage',
  props: {
    img: {
      type: String,
      required: true,
    },
    backgroundPositionX: {
      type: String,
      required: false,
      default: 'center',
      validator: propValue => {
        return ['left', 'right', 'center'].includes(propValue);
      },
    },
    backgroundPositionY: {
      type: String,
      required: false,
      default: 'center',
      validator: propValue => {
        return ['top', 'bottom', 'center'].includes(propValue);
      },
    },
  },
  computed: {
    computedStyle() {
      let style = [];
      if (this.img) {
        style.push({ backgroundImage: `url(${require('@/assets/img/' + this.img)})` });
        style.push({ backgroundPosition: this.backgroundPositionX + ' ' + this.backgroundPositionY });
        // style.push({ property: 'value' });
      }
      return style;
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .bg-image {
    width: 100%;
    height: 100%;
    background: transparent center center / cover no-repeat;
  }
</style>
